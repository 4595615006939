import PropTypes from 'prop-types';
import React from 'react';
import { Theme } from '../../theme/Theme';
import Typography from './Typography';
const Heading = ({
  title,
  variant = 'heading2',
  tag = 'h2',
  color = 'bahamaBlue',
  ...p
}) => {
  if (title) {
    ({ variant, tag } = title);
    p.dangerouslySetInnerHTML = { __html: title.content };
  }
  return <Typography variant={variant} as={tag} color={color} {...p} />;
};
Heading.propTypes = {
  title: PropTypes.shape({
    content: PropTypes.string,
    variant: PropTypes.oneOf([
      'heading1',
      'heading2',
      'heading3',
      'heading4',
      'heading5',
      'sectionHeading2',
    ]),
    tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'div']),
  }),
  variant: PropTypes.oneOf([
    'heading1',
    'heading2',
    'heading3',
    'heading4',
    'heading5',
    'sectionHeading2',
  ]),
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'div']),
  color: PropTypes.oneOf(Object.keys(Theme.colors)),
};
export default Heading;
