import PropTypes from 'prop-types';
import React from 'react';
import { Theme } from '../../theme/Theme';
import StyledTypography from './Typography.style';

const Typography = React.forwardRef(
  ({ tag = 'div', variant = 'body', color, ...p }, ref) => {
    return (
      <StyledTypography
        ref={ref}
        as={tag}
        variant={variant}
        color={color}
        {...p}
      />
    );
  }
);
Typography.displayName = 'Typography';
Typography.propTypes = {
  variant: PropTypes.oneOf([...Object.keys(Theme.typography), 'inherit']),
  tag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'span',
    'div',
    'p',
    'li',
    'ul',
    'pre',
  ]),
  color: PropTypes.oneOf(Object.keys(Theme.colors)),
  lineHeight: PropTypes.string,
  fontSize: PropTypes.string,
};
export default Typography;
