
import React from 'react';
import * as Self from './module';
import { registerModule } from '../../app/modules/reactModules';
import Container from '../../components/global/Container';
import Heading from '../../components/global/Heading';

export const getServerSideProps = async () => {
  return {
    props: {},
    maxage: 60,
    staleWhileRevalidate: 300,
  };
};

const ReviewsModule = ({ title, ...p }) => {
  return (
    <Container {...p}>
      {!!title && <Heading title={title} />}
    </Container>
  );
};

export default ReviewsModule;

registerModule({ Reviews: Self });
