import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const typographyStyle = ({
  theme,
  variant,
  color,
  lineHeight,
  fontSize,
}) => css`
  ${variant !== 'inherit' && theme.typography[variant]};
  color: ${color ? theme.colors[color] : 'inherit'};
  ${lineHeight ? { lineHeight } : {}}
  ${fontSize ? { fontSize } : {}}
  &.italic, 
  .italic {
    font-style: italic;
  }
  &.bold,
  .bold {
    font-weight: ${theme.weights.bold};
  }
  &.underline,
  .underline {
    ${theme.typography.underline};
  }
`;

// eslint-disable-next-line react/display-name
const Component = React.forwardRef(({ as: T = 'div', ...props }, ref) => (
  <T {...props} ref={ref} />
));

export default styled(Component, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'color',
})`
  ${typographyStyle}
`;
